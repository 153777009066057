<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-tabs">
                    <div class="p-0 card-header border-bottom-0">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li class="px-3 py-2"><h3 class="card-title">{{ topic.name }}</h3></li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="name" class="control-label">Name*</label>
                                        <input v-model="form.name" name="name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('name')}">
                                        <has-error :form="form" field="name"></has-error>
                                    </div>

                                    <div class="form-group">
                                        <label for="description" class="control-label">Beschreibung</label>
                                        <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}"></ckeditor>
                                        <has-error :form="form" field="description"></has-error>
                                    </div>

                                    <div class="form-group row">
                                        <label for="latest" class="control-label col-sm-3 col-form-label col-form-label-sm">Unter "AKTUELLES" zu finden</label>
                                        <div class="col-sm-8">
                                            <input type="checkbox" class="form-check-input" name="latest" id="latest" :class="{'is-invalid': form.errors.has('latest')}" v-model="form.latest" style="position: static; margin-left: 0;">
                                            <has-error :form="form" field="latest"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="color" class="control-label col-sm-3 col-form-label col-form-label-sm">Überkategorie</label>
                                        <select class="form-control form-control-sm" v-model="form.parent_id">
                                            <option value="">Keine Überkategorie</option>
                                            <option v-for="topic in topics.data" :key="topic.id" :value="topic.id">{{ topic.name }}</option>
                                        </select>
                                    </div>
                                    
                                    <div class="form-group row">
                                        <label for="color" class="control-label col-sm-3 col-form-label col-form-label-sm">Farbe*</label>
                                        <select class="form-control form-control-sm" v-model="form.color" :class="{'is-invalid': form.errors.has('color')}">
                                            <option value="bg-success">Grün</option>
                                            <option value="bg-white">Weiß</option>
                                            <option value="bg-warning">Gelb</option>
                                            <option value="bg-danger">Rot</option>
                                            <option value="bg-info">Hellblau</option>
                                            <option value="bg-navy">Dunkelblau</option>
                                            <option value="bg-primary">Blau</option>
                                            <option value="bg-secondary">Grau</option>
                                        </select>
                                        <has-error :form="form" field="color"></has-error>
                                    </div>

                                    <div class="form-group row">
                                        <label for="icon" class="control-label col-sm-3 col-form-label col-form-label-sm">Icon</label>
                                        <select class="form-control form-control-sm" v-model="form.icon">
                                            <option value="fa-newspaper">Zeitung</option>
                                            <option value="fa-lightbulb">Glühbirne</option>
                                            <option value="fa-euro-sign">Euro</option>
                                            <option value="fa-graduation-cap">Lernen</option>
                                            <option value="fa-tools">Werkzeug</option>
                                            <option value="fa-envelope-open-text">Brief</option>
                                            <option value="fa-users">Personen</option>
                                            <option value="fa-truck">Truck</option>
                                            <option value="fa-cash-register">Kasse</option>
                                            <option value="fa-bullhorn">Megafon</option>
                                            <option value="fa-laptop">Laptop</option>
                                            <option value="fa-dolly">Logistik</option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <b-button size="sm" variant="success" @click="editTopic">Speichern</b-button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import ClassicEditor from '../../../assets/packages/ckeditor5/src/ckeditor';

export default {
    name: "Kategorie",
    title: "TopicDetails",
    data() {
        return {
            form: new window.Form({
                id: "",
                name: "",
                description: "",
                latest: false, 
                color: "", 
                icon: "", 
                position: "", 
                parent_id: "", 
                _method: "PUT",
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            sortBy: "created_at",
            sortDesc: false,
            params: {
                sort_field: 'created_at',
                sort_direction: 'desc',
                per_page: 25,
            },
            page: 1,
            topic: {},
            topics: {
                children: []
            },
            editMode: false,
        }
    },
    methods: {

        editModeOn() {
            this.editMode = true;
        },

        editModeOff() {
            this.form.reset();
            this.form.fill(this.producer);
            this.form._method ="PUT";
            this.editMode = false;
        },

        

        editTopic() {
            this.enabled = [];
            this.form
                .post("/topics/" + this.topic.id)
                .then(() => {
                    this.loadTopic();
                    this.editModeOff();
                    this.$swal({
                        icon: "success",
                        title: "Die Kategorie wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        loadTopic() {
            this.axios
                .get("/topics/" + this.$route.params.id)
                .then((response) => {
                    this.topic = response.data.data;
                    if(this.topic.name == null)
                    {
                        this.topic.name = "";
                    }
                    if(this.topic.description == null)
                    {
                        this.topic.description = "";
                    }
                    this.form.fill(this.topic);
                    this.form._method = "PUT";

                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadTopics(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/topics", {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.topics = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

    },
    created() {
        this.loadTopic();
        this.loadTopics();
    },
}
</script>

<style scoped></style>